<template>
  <div class="main">
    <div class="candidate-CourseList content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Courses</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Courses</h4>
        </a-col>
      </a-row>
      <div class="body">
        <h4>Courses Offered</h4>
        <ListCourse />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'

import ListCourse from '@/components/candidate/course/ListCourse.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ListCourse
  },
  setup (props, { emit }) {
    onBeforeMount(() => {
      emit('updateMenu')
    })
    return {
    }
  }
})
</script>

<style lang="scss">
.candidate-CourseList {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    h4 {
      color: #707070;
      font-family: "TT Norms Pro Medium";
      font-size: 20px;
      text-align: left;
      margin: 0px 0 20px 0;
      text-transform: capitalize;
    }
  }
}
@media only screen and (max-width: 450px) {
  .candidate-CourseList {
    .body .courses-list .course-details .gutter-box .chapters {
      flex-direction: column;
      margin: 10px 0 0 0;
    }
  }
}
</style>
