
import { defineComponent, onBeforeMount } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'

import ListCourse from '@/components/candidate/course/ListCourse.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ListCourse
  },
  setup (props, { emit }) {
    onBeforeMount(() => {
      emit('updateMenu')
    })
    return {
    }
  }
})
